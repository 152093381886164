export const taskStatus = {
  DRAW: "DRAW",
  TEMPLATING: "TEMPLATING",
  CLOSED: "CLOSED",
  REVIEW: "REVIEW",
  CANCELED: "CANCELED",
  FULFILLED: "FULFILLED",
};

export const orderStatus = {
  ORDER_CREATED: "ORDER_CREATED",
  IN_PROGRESS: "IN_PROGRESS",
  READY_FOR_FULFILL: "READY_FOR_FULFILL",
  WAITING_FOR_FULFILL: "WAITING_FOR_FULFILL",
  IN_FULFILL: "IN_FULFILL",
  FULFILLED: "FULFILLED",
  FULFILL_ERROR: "FULFILL_ERROR",
  CLOSED: "CLOSED",
};

export const permissions = {
  TASK_IMPORTING: "TASK_IMPORTING",
  TASK_DRAWING: "TASK_DRAWING",
  TASK_TEMPLATING: "TASK_TEMPLATING",
  TASK_REVIEW: "TASK_REVIEW",
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  TASKMARKET: "TASKMARKET",
  MANAGE_RATINGS: "MANAGE_RATINGS",
  FULFILL_ORDERS: "FULFILL_ORDERS",
  MANAGE_ORDERS: "MANAGE_ORDERS",
  MANAGE_TASKS: "MANAGE_TASKS",
  READ_ALL_USERS: "READ_ALL_USERS",
  WRITE_ALL_USERS: "WRITE_ALL_USERS",
  SETTINGS: "SETTINGS",
  STATISTICS: "STATISTICS",
  SEND_MAILS: "SEND_MAILS",
};

export const taskStatusPermissionsMapping = {
  [permissions.TASK_DRAWING]: taskStatus.DRAW,
  [permissions.TASK_TEMPLATING]: taskStatus.TEMPLATING,
  [permissions.MANAGER]: taskStatus.REVIEW,
};

export const dbCollection = {
  TASKS: "tasks",
  USERS: "users",
  MAIL: "mail",
  CUSTOMERS: "customers",
};

export const countryCodes = {
  DE: "DE",
  ES: "ES",
  AT: "AT",
  PL: "PL",
  LU: "LU",
  FR: "FR",
  BE: "BE",
};

export const domainByShopId = {
  AQ: {
    DE: "Artquarell.com",
    ES: "Artcuarela.es",
    PL: "Artkwarela.pl",
    FR: "Artquarelle.fr",
  },
  TM: {
    DE: "TierBeiMir.com",
  },
};

export const supportNameByShopId = {
  AQ: {
    DE: "Anna",
    ES: "Lisa",
    PL: "Lisa",
    FR: "Anna",
  },
  TM: {
    DE: "Lisa",
  },
};

export const shopifyNameByShopId = {
  AQ: {
    DE: "artquarell",
    ES: "artcuarela",
    PL: "6fa17e-4",
    FR: "3c781a-ad",
  },
  TM: {
    DE: "tierbeimir",
  },
};

export const shopAndCountryMap = Object.keys(shopifyNameByShopId).reduce(
  (acc, key) => {
    acc[key] = Object.keys(shopifyNameByShopId[key]);
    return acc;
  },
  {}
);

export const allowedTemplatePatternByShopAndCountry = {
  AQ: {
    DE: /^[a-zA-Z\u00E4\u00FCa-åa-ö-w 0-9 -?!&$_.:àáéèñńëúù]*$/,
    AT: /^[a-zA-Z\u00E4\u00FCa-åa-ö-w 0-9 -?!&$_.:àáéèñńëúù]*$/,
    ES: /^[a-zA-Z\u00E4\u00FCa-åa-ö-w 0-9 -?!&$_.:àáéèñńëúù]*$/,
    FR: /^[a-zA-Z\u00E4\u00FCa-åa-ö-w 0-9 -?!&$_.:àáéèñńëúù]*$/,
    PL: /^[\s\S]*$/,
  },
};

export const orderFilterCategories = {
  ALL: 0,
  DELAYED_FULFILL: 1,
  DELAYED_SHIPMENT: 2,
};

export const editStyles = [
  {
    text: "Person (1)",
    value: "1",
  },
  {
    text: "Car (2)",
    value: "2",
  },
  {
    text: "Animal (3)",
    value: "3",
  },
  {
    text: "Last Chance (4)",
    value: "4",
  },
];
