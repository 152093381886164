<template>
  <div class="orders-to-fulfill">
    <div
      :class="{ 'control-header--hide': !selectedOrders.length }"
      class="control-header"
    >
      <div class="control-header__selected-items">
        Selected {{ selectedOrders.length }} Orders
      </div>
      <v-btn @click="checkAndFulfillSelectedOrders" width="300px"
      >Fulfill</v-btn
      >
    </div>
    <v-data-table
      :show-expand="true"
      :headers="headers"
      :items="ordersToFulfill"
      :loading="loadingOrders"
      disable-pagination
      :hide-default-footer="true"
      show-select
      v-model="selectedOrders"
      item-key="uid"
      :single-select="false"
      :item-class="getRowClass"
    >
      <template v-slot:item.orderNote="{ item }">
        <div class="d-flex">
          <OrderNoteTooltip :order="item" />
          <SupportDialog :order-id="item.uid"/>
        </div>
      </template>
      <template v-slot:item.resultsSent="{ item }">
        {{ dateFromNow(item.resultsSentDate) }}
      </template>
      <template v-slot:item.orderName="{ item }">
        <a :class="{'express-order-name': item.isExpress}" @click="() => openOrder(item.uid)">
          {{ item.orderName }}
          <v-icon v-if="item.isExpress" color="#b4b412">mdi-flash</v-icon>
        </a>
      </template>
      <template v-slot:item.orderDate="{ item }">
        <div class="d-flex">
          <FeedbackTooltip :key="task.uid" v-for="task of item.tasks" :feedback="task.feedback"/>
          <TimeIndicator :creation-date="item.orderDate" />
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        {{
          item.shippingAddress.firstName + " " + item.shippingAddress.lastName
        }}
      </template>
      <template v-slot:item.company="{ item }">
        {{ item.shippingAddress.company }}
      </template>
      <template v-slot:item.addressPrimary="{ item }">
        {{ item.shippingAddress.addressPrimary }}
      </template>
      <template v-slot:item.addressSecondary="{ item }">
        {{ item.shippingAddress.addressSecondary }}
      </template>
      <template v-slot:item.postCode="{ item }">
        {{ item.shippingAddress.postCode }}
      </template>
      <template v-slot:item.city="{ item }">
        {{ item.shippingAddress.city }}
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <v-icon class="mr-6" @click="() => editOrder(item)"
            >mdi-pencil</v-icon
          >
          <v-icon @click="(e) => openItemMenu(e, item.uid)">mdi-dots-vertical</v-icon>
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <OrderLineItems :order-tasks="item.tasks" />
        </td>
      </template>
    </v-data-table>
    <ListMenu :show="showMenu" :x="x" :y="y" :list-items="menuItems" />
    <EditOrderAddressDialog
      v-if="showEditOrderDialog"
      :show="showEditOrderDialog"
      :order="orderToEdit"
      :on-close="closeEditOrderDialog"
    />
    <ConfirmDialog
      :show="showWarnDialog"
      :message="warnDialogMessage"
      accept-text="Fulfill Orders"
      accept-color="warning"
      icon="mdi-alert"
      :title="warnDialogTitle"
      :on-accept="fulfillSelectedOrders"
      :on-cancel="closeWarnDialog"
    />
    <IsFulfillingDialog
      v-if="showIsFulfillingDialog"
      :orders-to-fulfill="selectedOrders"
      :show="showIsFulfillingDialog"
      :on-close="closeIsFulfillingDialog"
    />
    <OrderMailDialog
        v2
        :on-sent="markOrderForAwaitingAddress"
        v-if="showMailDialog"
        :show="showMailDialog"
        :on-close="() => (this.showMailDialog = false)"
        :order-id="selectedOrderId"
        mail-category="FULFILL"
    />
    <ManualFulfillDialog :order-id="selectedOrderId" :onClose="closeManualFulfillmentDialog" :show="showManualFulfillmentDialog" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimeIndicator from "@/components/TaskCards/components/TimeIndicator";
import ConfirmDialog from "@/components/ConfirmDialog";
import EditOrderAddressDialog from "@/components/FulfillOrders/components/EditOrderAddressDialog";
import IsFulfillingDialog from "@/components/FulfillOrders/components/IsFulfillingDialog";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../fb";
import { orderStatus } from "@/constants";
import OrderLineItems from "@/components/FulfillOrders/components/OrderLineItems";
import ListMenu from "@/components/ListMenu";
import ManualFulfillDialog from "@/components/FulfillOrders/components/ManualFulfillDialog";
import MailDialog from "@/components/MailDialog";
import { getMailTemplatesFulfill } from "@/content/mails";
import OrderNoteTooltip from "@/components/OrderNoteTooltip";
import {getDateFromNow, openOrderModal} from "@/utils";
import FeedbackTooltip from "@/components/FeedbackTooltip";
import SupportDialog from "@/components/Support/SupportTooltip";
import OrderMailDialog from "@/components/OrderMailDialog.vue";

export default {
  name: "OrdersToFulfill",
  components: {
    OrderMailDialog,
    SupportDialog,
    FeedbackTooltip,
    OrderNoteTooltip,
    MailDialog,
    ManualFulfillDialog,
    ListMenu,
    OrderLineItems,
    IsFulfillingDialog,
    EditOrderAddressDialog,
    ConfirmDialog,
    TimeIndicator,
  },
  data() {
    return {
      selectedOrders: [],
      showWarnDialog: false,
      showEditOrderDialog: false,
      showIsFulfillingDialog: false,
      orderToEdit: null,
      showMenu: false,
      showManualFulfillmentDialog: false,
      selectedOrderId: '',
      showMailDialog: false,
      x: 0,
      y: 0,
      menuItems: [
        {
          icon: "mdi-printer",
          onClick: this.showManualFulfillDialog,
          label: "Add Manual Fulfillment",
        },
        {
          icon: "mdi-email-outline",
          onClick: () => {
            this.showMailDialog = true;
          },
          label: "Send Mail",
        },
        {
          icon: "mdi-email-search-outline",
          onClick: this.searchOrderInMails,
          label: "Search In Mails",
        },
      ],
      warnDialogMessage:
        "There are Orders where the System shows a warning. Only continue if you are sure the warning will not result in an error when fulfilling the orders! ",
    };
  },
  computed: {
    ...mapGetters(["ordersToFulfill", "loadingOrders"]),
    headers() {
      return [
        { text: "Order", value: "orderName" },
        { text: "Notes", value: "orderNote" },
        { text: "Mailed Results", value: "resultsSent" },
        { text: "Age (days)", value: "orderDate" },
        { text: "Name", value: "name" },
        { text: "Company", value: "company" },
        { text: "Primary Address", value: "addressPrimary" },
        { text: "Secondary", value: "addressSecondary" },
        { text: "Post Code", value: "postCode" },
        { text: "City", value: "city" },
        { text: "Actions", value: "action" },
      ];
    },
    defaultMails() {
      return getMailTemplatesFulfill(this.selectedOrder);
    },
    numberSelectedOrdersWithWarning() {
      return this.ordersToFulfill.filter((order) => {
        return this.selectedOrders.find(
          (selectedOrder) =>
            selectedOrder.uid === order.uid && order.showOrderWarning
        );
      }).length;
    },
    warnDialogTitle() {
      return `Fulfill ${this.numberSelectedOrdersWithWarning} ${
        this.numberSelectedOrdersWithWarning > 1 ? "Orders" : "Order"
      } with Warning`;
    },
    selectedOrder() {
      return this.ordersToFulfill.find(order => order.uid === this.selectedOrderId)
    }
  },
  methods: {
    openOrder(uid) {
      openOrderModal(uid)
    },
    showManualFulfillDialog() {
      this.showManualFulfillmentDialog = true;
    },
    closeManualFulfillmentDialog() {
      this.showManualFulfillmentDialog = false;
    },
    openItemMenu(e, orderId) {
      e.preventDefault();
      this.selectedOrderId = orderId
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    checkAndFulfillSelectedOrders() {
      if (this.numberSelectedOrdersWithWarning) {
        this.showWarnDialog = true;
      } else {
        this.fulfillSelectedOrders();
      }
    },
    editOrder(order) {
      this.orderToEdit = order;
      this.showEditOrderDialog = true;
    },
    searchOrderInMails() {
      window.open(
        `https://mail.google.com/mail/u/?authuser=lisa@liebespferd.com&tab=wm#search/%23${
          this.selectedOrder.orderName.split("#")[1]
        }`,
        "_blank"
      );
    },
    dateFromNow(date) {
      return date ? getDateFromNow(date) : "N/A"
    },
    closeEditOrderDialog() {
      this.showEditOrderDialog = false;
      this.orderToEdit = null;
    },
    closeIsFulfillingDialog() {
      this.showIsFulfillingDialog = false;
    },
    closeWarnDialog() {
      this.showWarnDialog = false;
    },
    async markOrderForAwaitingAddress() {
      const orderDoc = doc(db, "orders", this.selectedOrder.uid);
      if (!this.selectedOrder.supportPending) {
        await setDoc(orderDoc,{
          supportPending: true,
          resolveOnNewAddress: true
        }, { merge: true })
      }
    },
    async fulfillSelectedOrders() {
      this.showWarnDialog = false;
      this.showIsFulfillingDialog = true;
      for await (const order of this.selectedOrders) {
        const orderDocRef = doc(db, "orders", order.uid);
        try {
          await setDoc(
            orderDocRef,
            {
              status: orderStatus.WAITING_FOR_FULFILL,
              fulfillTime: Date.now(),
            },
            { merge: true }
          );
          this.selectedOrders = this.selectedOrders.filter(
            (o) => o.uid !== order.uid
          );
        } catch (error) {
          console.error("Error updating order document:", error);
        }
      }
      this.showIsFulfillingDialog = false;
    },
    orderHasAllTasksSatisfied(tasks) {
      return (tasks.filter(task => task.feedback && task.feedback.satisfied)).length === tasks.length
    },
    orderHasTasksUnsatisfied(tasks) {
      return tasks.some(task => task.feedback && !task.feedback.satisfied)
    },
    getRowClass(item) {
      if (this.orderHasTasksUnsatisfied(item.tasks)) {
        return 'dark-red-row'
      } else if (item.orderNote) {
        return 'red-row'
      } else if (item.supportPending) {
        return 'grey-row'
      } else if (item.showOrderWarning) {
        return 'orange-row'
      } else if (this.orderHasAllTasksSatisfied(item.tasks)) {
        return 'green-row'
      } else {
        return ''
      }
    },
    disableRowSelect(item) {
      return item.orderNote
    }
  },
};
</script>

<style lang="scss">
.orange-row {
  background-color: #ffe7c2 !important; /* Set the desired background color */
}

.red-row {
  background-color: #ffabab !important; /* Set the desired background color */
}

.dark-red-row {
  background-color: #ff0000 !important; /* Set the desired background color */
}

.green-row {
  background-color: #ebffd0 !important; /* Set the desired background color */
}

.grey-row {
  background-color: #b4b4b4 !important; /* Set the desired background color */
}

.control-header {
  position: fixed;
  top: 72px;
  left: 80px;
  width: calc(100vw - 100px);
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  padding: 12px;
  border: black solid 1px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: opacity ease-in 0.5s;
  opacity: 1;

  &--hide {
    opacity: 0;
    pointer-events: none;
  }

  &__selected-items {
    font-size: 16px;
    line-height: 16px;
  }
}

.orders-to-fulfill .v-data-table .v-data-table__wrapper table {
  width: 100%;
}

.orders-to-fulfill .v-data-table .v-data-table__wrapper table tr td:last-child,
.orders-to-fulfill .v-data-table .v-data-table__wrapper table tr th:last-child {
  position: sticky;
  right: 0;
  z-index: 1;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.36);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.1px);
  -webkit-backdrop-filter: blur(13.1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.express-order-name {
  color: #b4b412 !important;
  font-weight: bolder;
}
</style>
